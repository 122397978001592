@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes increaseDashArray {
  from {
    stroke-dasharray: 200;
  }
  to {
    stroke-dasharray: 1000;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.hexagon {
  width: 360px;
  height: 350px;
}

.animate-dash-array {
  animation: increaseDashArray 2s linear forwards;
}

.hexagonPath1 {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  stroke: rgba(255, 255, 255, 0.7);
  animation: dash 3s linear forwards;
}

.hexagonPath2 {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  stroke: rgba(255, 255, 255, 0.7);
  animation: dash 3s linear forwards;
}

.hexagonPath3 {
  stroke-dasharray: 800;
  stroke-dashoffset: 2400;
  animation: dash 9s infinite alternate linear forwards;
}

.card {
  width: 150px;
  height: 150px;
  font-size: larger;
  font-weight: 600;
}

.border {
  stroke-dasharray: 2200; 
  stroke-dashoffset: 3200; 
  animation: drawBorder 20s infinite alternate;
}

@keyframes drawBorder {
  to {
    stroke-dashoffset: 0;
  }
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@screen md {
  .card {
    width: 220px;
    height: 220px;
  }
}

@keyframes shake-y {
  0% { transform: translateY(0); }
  50% { transform: translateY(-7px); }
  100% { transform: translateY(0); }
}

.animate-shake-y {
  animation: shake-y 0.8s infinite;
}
